@import 'src/common';

.vision {
  .wrap {
    display: grid;
    align-items: center;
    justify-content: space-between;
    width: 1400px;
    margin: auto;
    padding: 120px 0;
    grid-template-columns: 1fr 80px 1fr;

    .left_side {
      .top {
        @include flex-center-center;
        flex-direction: column;

        .a {
          font-size: 2.2rem;
          font-weight: 600;
          letter-spacing: 1.3px;
          font-family: 'NanumSquare', serif;
        }

        .b {
          font-size: 2.7rem;
          font-weight: bold;
          letter-spacing: -1.3px;
          color: #1059B9;
          font-family: 'NanumSquare', serif;
          margin-top: 10px;

          strong {
            font-size: 3.0rem;
            margin-left: 5px;
            color: #013D80;
          }
        }

        .c {
          font-family: HaenamFont, serif;
          letter-spacing: 3px;
          color: #e6e6e6;
          font-style: italic;
          font-size: 3.6rem;
          font-weight: bold;
          margin-top: 15px;
        }
      }

      .middle {
        @include flex-center-between;
        flex-direction: column;
        height: 430px;
        margin: 55px 0;
        opacity: 50%;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;

          &:nth-child(1) {
            background-color: #00B5A8;

            &:before {
              content: "";
              display: block;
              width: 2px;
              height: 200px;
              background-color: #B3B3B3;
              margin: 10px 0 0 4px;
            }
          }

          &:nth-child(2) {
            background-color: #009FD6;

            &:before {
              content: "";
              display: block;
              width: 2px;
              height: 200px;
              background-color: #B3B3B3;
              margin: 10px 0 0 4px;
            }
          }

          &:nth-child(3) {
            background-color: #0050A8;
          }
        }
      }

      .bottom {
        margin: auto;
        width: 242px;
        color: #A8CFE5;

        .dotdot_start,
        .dotdot_end {
          font-size: 5.5rem;
          font-family: 'Noto Sans KR', serif;
        }

        .dotdot_end {
          text-align: right;
        }

        .txt {
          font-size: 2.8rem;
          text-align: center;

          span {
            font-size: 3.6rem;
            letter-spacing: 3.3px;
            font-weight: bold;
          }
        }
      }
    }

    .dot_bar {
      @include flex-center-between;
      flex-direction: column;
      height: 760px;
      margin: auto;
      padding-right: 50px;

      .dot {
        width: 18px;
        height: 18px;
        border-radius: 50%;

        &:nth-child(1) {
          background-color: #00B5A8;

          &:before {
            content: "";
            display: block;
            width: 4px;
            height: 365px;
            background-color: #B3B3B3;
            margin: 18px 0 0 7px;
          }
        }

        &:nth-child(2) {
          background-color: #009FD6;

          &:before {
            content: "";
            display: block;
            width: 4px;
            height: 365px;
            background-color: #B3B3B3;
            margin: 18px 0 0 7px;
          }
        }

        &:nth-child(3) {
          background-color: #0050A8;
        }
      }
    }

    .right_side {
      @include flex-center-start;

      ul {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 50px;

        li {
          margin: auto;
          width: 82%;
          height: 100%;
          border-radius: 30px;
          overflow: hidden;
          transform: translateZ(0);
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        li:nth-child(1) {box-shadow: 0 0 6px 12px #ccf2ec;}
        li:nth-child(2) {box-shadow: 0 0 6px 12px #c4f2f7;}
        li:nth-child(3) {box-shadow: 0 0 6px 12px #b3edff;}
      }
    }
  }
}

.vision_mobile {
  margin: 0 auto;
  text-align: center;

  .top {
    margin-top: 40px;

    span {
      display: block;
      margin-top: 6px;
      font-weight: bold;
    }

    .a {
      font-family: 'NanumSquare', serif;
      font-size: 20px;
      color: #161c61;
    }
    .b {
      font-family: 'NanumSquare', serif;
      font-size: 20px;
      letter-spacing: -2px;
      color: #1659b9;

      strong {
        margin-left: 6px;
        font-size: 25px;
        letter-spacing: 0;
      }
    }
    .c {
      font-family: HaenamFont, serif;
      font-size: 30px;
      letter-spacing: 3px;
      color: #e6e6e6;
      font-style: italic;

    }
  }

  .middle {
    margin-top: 50px;

    .box {
      margin: auto;
      width: 80%;

      video {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .shadow_a {box-shadow: 0 0 3px 6px #ccf2ec;}
      .shadow_b {box-shadow: 0 0 3px 6px #c4f2f7;}
      .shadow_c {box-shadow: 0 0 3px 6px #b3edff;}
    }

    .desc {
      margin-top: 13px;
      margin-bottom: 50px;
      font-weight: bold;
      font-family: NanumSquareEB, serif;
      font-size: 15px;
    }
  }

  .bottom {
    margin: 80px 0 60px;
    position: relative;

    p {
      color: #a8cfe5;
      font-weight: bold;
    }

    .dotdot_start, .dotdot_end {
      position: absolute;
      font-family: 'Noto Sans KR', serif;
      font-size: 40px;
    }
    .dotdot_start {
      left: 91px;
      top: -27px;
    }
    .dotdot_end {
      right: 103px;
      bottom: -28px;
    }
    .up {
      font-family: 'NanumSquareRound', serif;
      font-size: 23px;
    }
    .down {
      font-family: 'NanumSquareRound', serif;
      font-size: 25px;
      margin-top: 5px;
    }
  }
}
