@import 'src/common';

.Performance {
  width: 1400px;
  margin: 50px auto 0 auto;

  section {
    width: 100%;
    height: 800px;
    background-size: cover;
    position: relative;

    &.right {
      .title_wrap {
        width: 350px;
        position: absolute;
        top: 70px;
        left: 470px;
        text-align: right;
        color: #000F90;
        font-weight: bold;

        .title {
          font-size: 4.5rem;
        }

        .title_en {
          font-size: 4rem;
          font-style: italic;
          font-family: NotoSerifKR-Regular, serif;
        }
      }

      .picture_wrap {
        position: absolute;
        top: 36px;
        left: 896px;
      }
    }

    &.left {
      .title_wrap {
        width: 300px;
        position: absolute;
        top: 70px;
        left: 590px;
        color: white;
        font-weight: bold;
        font-size: 4rem;

        .title {
          font-size: 4.5rem;
        }

        .title_en {
          font-size: 4rem;
          font-style: italic;
          font-family: NotoSerifKR-Regular, serif;
        }
      }

      .picture_wrap {
        position: absolute;
        top: 36px;
        left: 136px;
      }
    }

    .picture {
      width: 320px;
      height: 200px;
      background-size: cover;
      margin: 36px;
      border-radius: 16px;
    }
  }
}

.Performance_mobile {
  width: 100%;
  margin: 0 auto;

  section {
    width: 100%;
    margin: 0 auto;

    &.left {
      background-color: #f4f4f4;
    }

    .title_wrap {
      width: 100%;
      text-align: center;
      color: #000F90;
      font-weight: bold;
      padding-top: 30px;
      padding-bottom: 10px;

      .title {
        font-size: 1.6rem;
      }

      .title_en {
        font-size: 1.4rem;
        font-style: italic;
        font-family: NotoSerifKR-Regular, serif;
      }
    }

    .picture_wrap {
      @include flex-center-center;
      flex-wrap: wrap;
      padding-bottom: 30px;

      .picture {
        width: 44%;
        height: 120px;
        background-size: cover;
        border-radius: 16px;
        box-shadow: 3px 0 15px 0 rgba(49, 49, 49, 0.16);
        margin: 10px;
      }
    }
  }
}
