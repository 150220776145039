.seminar {
  margin: 0 auto;

  .top {
    background-image: url("../../img/poster.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 625px;
  }

  .youtube {
    width: 100%;
    height: 250px;
  }

  .mid {
    background-image: url("../../img/seminar_txtBg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    padding: 26% 0;
    line-height: 1.4;

    .common { // 배경 빼고 전체 좌우패딩값 주기..ㅋ
      padding: 0 7%;
    }

    .mid_title {
      display: flex;
      flex-direction: column;
      justify-content: left;
      font-weight: 600;
      font-size: 7.2vw;
      letter-spacing: 1.4px;

      span {
        font-size: 4.2vw;
        font-weight: normal;
        margin-top: 2px;
        opacity: 66%;
      }

      &:after {
        display: block;
        content: "";
        width: 100%;
        height: 1px;
        background: #ffffff;
        margin: 10px 0 40px;
      }
    }

    .mid_sub1 {
      display: flex;
      flex-direction: column;
      justify-content: left;
      letter-spacing: 1.2px;
      word-break: keep-all;

      .sub_title {
        font-size: 5.2vw;
        font-weight: 600;
      }

      p {
        font-size: 4.2vw;
        margin: 15px 0 45px;
      }
    }

    .mid_sub2 {
      background-image: url("../../img/seminar_pointBox.svg");
      background-size: cover;
      background-repeat: no-repeat;
      height: 148px;
      padding: 7% 9%;
      font-size: 4.2vw;
      letter-spacing: 1.2px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;

      strong {
        font-size: 5vw;
      }
    }

    .mid_sub3 {
      font-size: 4.2vw;
      letter-spacing: 1.2px;
      margin-top: 50px;
      word-break: keep-all;

      span {
        font-size: 5vw;
        font-weight: 600;
      }
    }
  }

  .bottom {
    padding: 0 7%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sec {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 80px;

      .eng {
        border-radius: 82px;
        font-size: 4vw;
        letter-spacing: 1.4px;
        color: #ffffff;
        height: 22px;
        width: fit-content;
        padding: 25px 25px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(120deg, #082572 75%, #1CB5E0);
      }

      .kor {
        font-size: 10vw;
        margin-top: 30px;
        font-weight: 600;
        color: #082572;
        letter-spacing: 1.5px;
      }
    }

    ul {
      margin-top: 70px;

      li {
        border-top: 1px solid #e2e2e2;
        padding: 20px 0;
        display: flex;
        align-items: flex-start;
        justify-items: flex-start;
        font-size: 4.2vw;

        &:last-child {
          border-bottom: 1px solid #e2e2e2;
        }

        .tag {
          width: 30%;
          color: #082572;
          font-weight: 600;
        }

        .cont {
          width: 70%;
          color: #1e1e1e;
          line-height: 1.4;
          word-break: keep-all;

          span {
            font-weight: 600;
          }
        }
      }
    }

    .speaker {
      width: 250px;
      height: 250px;
      border-radius: 50%;
      background-image: url("../../img/speaker.JPG");
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 70px;
    }

    .speaker_intro {
      margin: 70px 0;

      .title {
        font-size: 4vw;
        color: #666666;
        line-height: 1.5;
        padding-bottom: 10px;
        border-bottom: 1px solid #666666;

        span {
          font-size: 5.2vw;
          font-weight: bold;
          letter-spacing: 1.5px;
          color: #082572;
        }
      }

      .txt {
        font-size: 4.2vw;
        margin: 18px 0;
        line-height: 1.3;
        letter-spacing: 1.2px;
        color: #1e1e1e;
        word-break: keep-all;
      }
    }
  }
}
