.history-fixed-title {
  position: fixed;
  left: 5rem;
  bottom: 0;
  font-weight: 500;
  opacity: 0.5;
  pointer-events: none;
  font-family: "Noto Serif KR";
  z-index: 30;
  font-size: 3rem;
  @media (min-width: 800px) {
    font-size: 7rem;
  }
}
