.prize {
  section {
    padding: 60px 0;
  }
  .gray_back {
    background-color: #f4f4f4;
  }
  .container {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    display: grid;

    span {
      width: 100%;
      padding: 20px;
      img {
        border-radius: 15px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .v1 {
    grid-template-columns: 1fr 1fr 1fr;
    align-items: end;
  }
  .v2 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    span {
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
  .v3 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .v4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    span {
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
}

.prize_mobile {
  .gray_back {
    background-color: #f4f4f4;
  }
  .container {
    margin: auto;
    display: grid;
    padding: 20px;

    span {
      width: 100%;
      padding: 5px;
      img {
        border-radius: 5px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .v1 {
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }
  .v2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    span {
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
  .v3 {
    grid-template-columns: 1fr 1fr;
    padding: 20px 45px;
    span {
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
  .v4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    span {
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
}