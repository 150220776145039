.relation-center-history {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .image-rotator {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 0;
    opacity: 0.2;
  }
  .history-list {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    .history-list-item {
      position: relative;
      overflow: hidden;
      height: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid;
      border-color: #ffffff33;
      font-size: 18px;
      &:first-of-type {
        border-top: 1px solid;
        border-color: #ffffff33;
      }
      > span {
        z-index: 10;
      }
      > img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        object-fit: cover;
        z-index: 0;
        transition: all 0.4s;
        background-color: #000000;
        mix-blend-mode: multiply;
      }
      &:hover > img {
        opacity: 0.7;
      }
    }
  }
  @media (min-width: 1280px) {
    .history-list {
      display: flex;
      height: 100%;
      flex-direction: row;
      .history-list-item {
        width: 240px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: "Noto Serif KR";
        font-size: 18px;
        cursor: pointer;
        border-right: 1px solid;
        border-bottom: none;
        border-top: none;
        border-color: #ffffff33;
        &:first-of-type {
          border-top: none;
          border-left: 1px solid;
          border-color: #ffffff33;
        }
      }
    }
  }
}
