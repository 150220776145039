@import 'src/common';

.Symbol {
  width: 1400px;
  margin: 0 auto;
  padding: 30px;

  section {
    @include flex-center-center;

    img {
      width: 300px;
      margin: 50px;
    }

    .desc {
      min-width: 500px;

      .title {
        color: #06589f;
        font-size: 2.2rem;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .content {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.8;
      }
    }

    &.middle {
      margin: 70px 0;

      img {
        position: relative;
        left: 20px;
        bottom: 50px;
      }
    }

    &.bottom {

      .bottom_div {
        @include flex-center-between;
        flex-direction: column;
        margin: 70px;
        height: 500px;

        .bottom_img {
          width: 380px;
          height: 380px;
        }

        .desc {
          min-width: 300px;
          height: 120px;

          .title {
            margin-bottom: 10px;
          }

          .verse {
            color: #717071;
            margin-left: 248px;
          }
        }
      }
    }
  }

  .hr_line {
    width: 500px;
    height: 1px;
    background: #cbcbcb;
    margin: 0 auto;
  }
}

.Symbol_mobile {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 30px;

  section {
    @include flex-center-center;
    flex-direction: column;
    margin-bottom: 60px;

    img {
      width: 70%;
      margin: 50px;
    }

    .desc {
      width: 100%;

      .title {
        color: #06589f;
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .content {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.8;
      }
    }

    &.bottom {

      .bottom_div {
        margin: 20px;

        .desc {
          min-width: 300px;

          .title {
            margin-bottom: 10px;
          }

          .verse {
            color: #717071;
            margin-left: 180px;
          }
        }
      }
    }
  }

  .hr_line {
    width: 50%;
    height: 1px;
    background: #cbcbcb;
    margin: 0 auto;
  }
}
