.not-found {
  position: absolute;
  width: 780px;
  height: 600px;
  top: 50%;
  left: 50%;
  margin-top: -340px;
  margin-left: -390px;

  img {
    width: 600px;
  }

  p {
    font-family: SangSangFlowerRoad, serif;
    font-size: 70px;
    position: absolute;
    top: 403px;
    right: 77px;
  }
}

@media (max-width: 770px) {
  .not-found {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    padding: 0 30px;
    margin-top: -67%;
    margin-left: -50%;

    img {
      width: 100%;
    }

    p {
      font-family: SangSangFlowerRoad, serif;
      font-size: 50px;
      position: relative;
      top: 0;
      right: 0;
      text-align: center;
    }
  }
}