@import 'src/common';

.Sports {
  width: 100%;
  height: 6300px;
  margin: 50px auto;

  .background_wrap {
    .background {
      width: 100%;
      height: 500px;
      background-size: cover;
      background-position: center;
      position: absolute;

      &.reverse {
        transform: scaleX(-1);
      }

      &:nth-child(1) {
        top: 737px;
      }

      &:nth-child(2) {
        top: 1360px;
      }

      &:nth-child(3) {
        top: 2178px;
      }

      &:nth-child(4) {
        top: 3200px;
      }

      &:nth-child(5) {
        top: 4000px;
      }

      &:nth-child(6) {
        top: 5000px;
      }

      &:nth-child(7) {
        top: 6250px;
      }
    }
  }

  .person_wrap {
    width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 10;

    img:nth-child(1) {
      width: 150px;
      position: absolute;
      top: 557px;
      left: 1179px;
    }

    img:nth-child(2) {
      width: 150px;
      position: absolute;
      top: 3743px;
      left: -48px;
    }

    img:nth-child(3) {
      width: 200px;
      position: absolute;
      top: 5941px;
      left: 61%;
    }
  }

  .desc_wrap {
    width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 10;

    .desc {
      position: absolute;

      h1 {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.5rem;
      }
    }

    .desc:nth-child(1) {
      top: 411px;
      left: 25px;
    }

    .desc:nth-child(2) {
      top: 307px;
      left: 752px;
    }

    .desc:nth-child(3) {
      top: 504px;
      left: 881px;
    }

    .desc:nth-child(4) {
      top: 833px;
      left: 322px;
    }

    .desc:nth-child(5) {
      top: 1504px;
      left: 369px;
    }

    .desc:nth-child(6) {
      top: 1439px;
      left: 920px;
    }

    .desc:nth-child(7) {
      top: 1907px;
      left: 1028px;
    }

    .desc:nth-child(8) {
      top: 2225px;
      left: 164px;
    }

    .desc:nth-child(9) {
      top: 3338px;
      left: 676px;
    }

    .desc:nth-child(10) {
      top: 3709px;
      left: 746px;
    }

    .desc:nth-child(11) {
      top: 5732px;
      left: 540px;
      text-align: center;

      p {
        font-size: 2.4rem;
        margin-bottom: 10px;
      }

      h1 {
        font-size: 3rem;
      }
    }
  }

  .img_wrap {
    width: 1400px;
    margin: 0 auto;
    position: relative;

    img {
      position: absolute;
      border-radius: 16px;
      box-shadow: 7px 7px 10px 0 rgba(49, 49, 49, 0.16);
    }

    img:nth-child(1) {
      width: 600px;
      left: 0;
    }

    img:nth-child(2) {
      width: 400px;
      left: 750px;
    }

    img:nth-child(3) {
      width: 400px;
      top: 460px;
      left: 450px;
    }

    img:nth-child(4) {
      width: 600px;
      top: 800px;
      left: 600px;
    }

    img:nth-child(5) {
      width: 500px;
      top: 1150px;
      left: 350px;
    }

    img:nth-child(6) {
      width: 400px;
      top: 1150px;
      left: 905px;
    }

    img:nth-child(7) {
      width: 500px;
      top: 1600px;
      left: 500px;
    }

    img:nth-child(8) {
      width: 400px;
      top: 2050px;
      left: 680px;
    }

    img:nth-child(9) {
      width: 500px;
      top: 2300px;
      left: 150px;
    }

    img:nth-child(10) {
      width: 600px;
      top: 2400px;
      left: 729px;
    }

    img:nth-child(11) {
      width: 450px;
      top: 2950px;
      left: 150px;
    }

    img:nth-child(12) {
      width: 750px;
      top: 2800px;
      left: 652px;
    }

    img:nth-child(13) {
      width: 400px;
      top: 3350px;
      left: 1000px;
    }

    img:nth-child(14) {
      width: 500px;
      top: 3700px;
      left: 160px;
    }

    img:nth-child(15) {
      width: 670px;
      top: 3800px;
      left: 730px;
    }
    img:nth-child(16) {
      width: 670px;
      top: 4100px;
    }

    img:nth-child(17) {
      width: 400px;
      top: 4300px;
      left: 726px;
    }

    img:nth-child(18) {
      width: 1400px;
      top: 4620px;
    }
  }
}

.Sports_mobile {
  @include flex-center-center;
  flex-wrap: wrap;
  width: 95%;
  margin: 30px auto 0 auto;

  .img_wrap {
    width: 47%;
    margin: 0 5px;

    img {
      width: 100%;
      height: 120px;
      border-radius: 16px;
      box-shadow: 3px 0 15px 0 rgba(49, 49, 49, 0.16);
      margin-bottom: 10px;
    }

    .desc {
      height: 80px;
      line-height: 1.5;

      h1 {
        font-size: 1.2rem;
        font-weight: bold;
        word-break: keep-all;
      }

      p {
        font-size: 1.1rem;
      }
    }

    &.large {
      width: 100%;

      img {
        height: auto;
      }
    }
  }
}
