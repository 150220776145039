.relationCenterLayout {
  height: 100vh;
  width: 100%;
  font-family: Pretendard;
  background-color: #282828;
  color: white;
  * ::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 9999pxa;
  }

  *::-webkit-scrollbar {
    height: 3px;
    width: 3px;
  }

  * ::-webkit-scrollbar-button {
    display: none;
  }
  .navigation {
    position: fixed;
    left: 32px;
    top: 24px;
    z-index: 50;
    .toggleBtn {
      color: white;
      font-size: 32px;
      z-index: 10;
      position: relative;
    }
    .navList {
      position: fixed;
      color: white;
      background-color: #282828dd;
      font-size: 16px;
      left: 0;
      top: 0;
      height: 100%;
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 80px;
      transition: transform 1s;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .itemLink {
        cursor: pointer;
        display: flex;
        gap: 8px;
        align-items: center;
        max-width: max-content;
      }
      .itemList {
        padding-left: 16px;
      }
      .recursiveList {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}
