.MainSpotlight {
  .item_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 125px;
  }
}

.MainSpotlight.mobile {
  .item_wrap {
    margin-bottom: 48px;
  }
}