@import "src/common";

.History {
  width: 1400px;
  margin: 100px auto;

  .pic_wrap {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    position: absolute;

    img {
      border-radius: 8px;
      margin-bottom: 8px;
    }

    span {
      font-size: 1.3rem;
      color: #717071;
    }
  }

  table {
    tr {
      td {
        font-size: 1.5rem;
        height: 30px;

        &.year {
          width: 120px;
        }
      }
    }
  }

  .top {
    @include flex-center-center;
    margin-bottom: 40px;

    .picture {
      width: 600px;
      height: 400px;
      border: 5px solid #ccf2ec;
      border-radius: 8px;
      position: relative;

      .pic_wrap:nth-child(1) {
        top: 20px;
        left: 50px;

        img {
          width: 180px;
        }
      }

      .pic_wrap:nth-child(2) {
        top: 190px;
        left: 220px;

        img {
          width: 250px;
        }
      }
    }

    .text {
      width: 480px;
      background: #ccf2ec;
      border-radius: 8px;
      padding: 30px;
      font-weight: bold;
      position: relative;
      bottom: 60px;
      right: 80px;

      .title {
        font-size: 2rem;
        color: #01b5a8;
        margin-bottom: 30px;
      }
    }
  }

  .middle {
    @include flex-center-center;
    margin-bottom: 80px;

    .picture {
      width: 800px;
      height: 600px;
      border: 5px solid #c4f2f7;
      border-radius: 8px;
      position: relative;

      .pic_wrap:nth-child(1) {
        align-items: flex-start;
        top: 20px;
        left: 220px;

        img {
          width: 180px;
        }
      }

      .pic_wrap:nth-child(2) {
        top: 40px;
        left: 480px;

        img {
          width: 200px;
        }
      }

      .pic_wrap:nth-child(3) {
        top: 200px;
        left: 220px;
        z-index: 20;

        img {
          width: 250px;
        }
      }

      .pic_wrap:nth-child(4) {
        top: 320px;
        left: 420px;
        z-index: 10;

        img {
          width: 350px;
        }
      }
    }

    .text {
      width: 440px;
      background: #c4f2f7;
      border-radius: 8px;
      padding: 30px;
      font-weight: bold;
      position: relative;
      left: 180px;
      z-index: 1;

      .title {
        font-size: 2rem;
        color: #009fd6;
        margin-bottom: 30px;
      }
    }
  }

  .bottom {
    @include flex-center-center;
    margin-bottom: 40px;
    position: relative;

    .picture {
      width: 1200px;
      height: 1400px;
      border: 5px solid #c5efff;
      border-radius: 8px;
      position: relative;

      .pic_wrap:nth-child(1) {
        top: 50px;
        left: 50px;

        img {
          width: 300px;
        }
      }

      .pic_wrap:nth-child(2) {
        top: 210px;
        left: 240px;

        img {
          width: 400px;
        }
      }

      .pic_wrap:nth-child(3) {
        top: 430px;
        left: 50px;
        z-index: 20;

        img {
          width: 450px;
        }
      }

      .pic_wrap:nth-child(4) {
        bottom: 35px;
        left: 50px;
        z-index: 10;

        img {
          width: 1100px;
        }

        span {
          position: absolute;
          top: 20px;
          right: 20px;
          font-size: 1.4rem;
        }
      }
    }

    .text {
      width: 380px;
      background: #c5efff;
      border-radius: 8px;
      padding: 30px;
      font-weight: bold;
      position: absolute;
      top: -40px;
      right: 200px;

      .title {
        font-size: 2rem;
        color: #0050a8;
        margin-bottom: 30px;
      }
    }
  }
}

.History_mobile {
  width: 100%;
  max-width: 400px;
  margin: 30px auto;

  .picture {
    @include flex-center-center;
    flex-wrap: wrap;
    width: 95%;
    border-radius: 8px;
    position: absolute;
    padding-top: 100px;

    .pic_wrap {
      @include flex-center-end;
      flex-direction: column;
      width: 50%;

      img {
        width: 90%;
        border-radius: 8px;
        margin-bottom: 8px;
      }

      span {
        font-size: 1rem;
        color: #717071;
      }
    }
  }

  .text {
    width: 87%;
    border-radius: 8px;
    padding: 20px;
    font-weight: bold;
    z-index: 1;

    .title {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }

    table {
      tr {
        height: 27px;

        td {
          font-size: 1.1rem;
          word-break: keep-all;

          &.year {
            width: 100px;
          }
        }
      }
    }
  }

  .top {
    @include flex-center-start;
    flex-direction: column;
    height: 400px;
    position: relative;
    margin-bottom: 30px;

    .picture {
      height: 262px;
      border: 5px solid #ccf2ec;
      top: 162px;
    }

    .text {
      background: #ccf2ec;

      .title {
        color: #01b5a8;
      }
    }
  }

  .middle {
    @include flex-center-start;
    flex-direction: column;
    height: 682px;
    position: relative;
    margin: 62px 0 30px;

    .picture {
      height: 438px;
      border: 5px solid #c4f2f7;
      top: 320px;
    }

    .text {
      background: #c4f2f7;

      .title {
        color: #009fd6;
      }
    }
  }

  .bottom {
    @include flex-center-start;
    flex-direction: column;
    height: 1345px;
    position: relative;
    margin-top: 100px;

    .picture {
      height: 860px;
      border: 5px solid #c5efff;
      top: 460px;

      .pic_wrap {
        width: 100%;
      }

      .pic_wrap:nth-child(1) {
        width: 70%;
      }

      .pic_wrap:nth-child(2) {
        width: 80%;
      }

      .pic_wrap:nth-child(3) {
        width: 90%;
      }
    }

    .text {
      background: #c5efff;

      .title {
        color: #0050a8;
      }
    }
  }
}
