.nation {
  section {
    text-align: center;
  }

  .top {
    .container {
      display: block;
      margin: 80px auto;
    }

    .box {
      display: inline-grid;
      width: 420px;
      height: 420px;
      margin: 20px;
      position: relative;
    }
    .main_box {
      span {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 30px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      span:hover {
        cursor: pointer;
        box-shadow: 0 0 25px 0 black;
      }
      .title {
        position: absolute;
        bottom: 0;
        height: 55px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        p {
          color: white;
          font-size: 27px;
          font-weight: bold;
          letter-spacing: 1.3px;
          top: 12px;
          position: relative;
        }
      }
    }
    .back {
      border-radius: 30px;
      background-color: #e7f8f8;
      display: grid;
      width: 100%;
      height: 100%;

      img {
        display: block;
        width: 60%;
        margin: 134px auto;
      }
    }
  }

  .container {
    width: 100%;
    max-width: 1400px;
    display: inline-flex;
    margin: 80px 0;
  }

  .v1 {
    background-color: #e7f8f8;

    .left {
      width: 70%;
      text-align: left;
      margin-right: 40px;
    }
    .right {
      width: 30%;
    }
  }
  .v2 {
    .left {
      width: 30%;
    }
    .right {
      width: 70%;
      text-align: right;
      margin-left: 40px;
    }
  }

  .img_box {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: cover;
    }
    span:not(:last-child) {
      padding-bottom: 15px;
    }
  }
  .title_box {
    display: grid;
    height: 100%;
    h1 {
      color: #005dac;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 1.2px;
    }
    p {
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: -1px;
    }
    span {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
      }
    }
  }
}

.nation_mobile {
  section {
    text-align: center;
  }

  .top {
    .container {
      margin: 20px auto;
      display: inline-grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .box {
      margin: 3px;
      position: relative;
    }
    .main_box {
      display: grid;
      span {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
      span:after {
        content: "";
        display: block;
        padding-bottom: 30%;
      }
      .title {
        position: absolute;
        top: 35%;
        display: grid;
        margin: auto;
        width: 100%;
        height: 30%;
        background-color: rgba(0, 0, 0, 0.4);

        p {
          margin: auto;
          color: white;
          font-size: 1.2rem;
          font-weight: bold;
          word-break: keep-all;
        }
      }
    }
    .back {
      border-radius: 10px;
      background-color: #e7f8f8;
      height: 100%;
      display: grid;
      align-content: center;

      img {
        display: block;
        width: 60%;
        margin: auto;
      }
    }
  }

  .container {
    display: inline-block;
    margin: 30px 0;
    padding: 0 15px;
  }

  .v1 {
    background-color: #e7f8f8;
  }
  .left {
    text-align: left;
    margin: auto;
  }

  .img_box {
    margin-top: 10px;
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      padding: 3px;
    }
  }
  .title_box {
    display: grid;
    h1 {
      color: #005dac;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 1.2px;
    }
    p {
      margin-top: 5px;
      font-size: 12px;
      line-height: 1.5;
      padding: 0 3px;
    }
    img {
      margin-top: 10px;
      width: 100%;
      border-radius: 6px;
    }
  }
}