.popup {
  font-family: "Noto Sans KR";
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  left: 20px;
  top: 20px;
  z-index: 50;
  width: 100%;
  aspect-ratio: 4/5;
  max-width: 350px;
  box-shadow: 5px 5px 5px #28282866;
  border-radius: 1rem;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: space-between;
  &.close {
    display: none;
  }
  figure {
    position: absolute;
    z-index:-10;
    width: 100%;
    height: 100%;
    cursor: pointer;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .title {
    color: #282828;
    background: #ffffff88;
    backdrop-filter: blur(16px);
    padding: 2rem 0;
    font-size: 2rem;
    white-space: nowrap;
    font-weight: 900;
    width: 100%;
    text-align: center;
  }
  .discription {
    background: #ffffff88;
    backdrop-filter: blur(16px);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.5rem;
    border-radius: 0.5rem;
    padding: 2rem;
    text-align: center;
  }
  a {
    padding: 1rem 2rem;
    white-space: nowrap;
    border-radius: 0.5rem;
    background-color: white;
    backdrop-filter: blur(16px);
    z-index: 20;
    font-size: 1.5rem;
    opacity: 0.5;
    transition: opacity 0.2s;
    &:hover {
      opacity: 1;
    }
  }
  .footer {
    margin-bottom: 20px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding-right: 20px;
    label {
      z-index: 10;
      font-size: 1.2rem;
      cursor: pointer;
    }
    button {
      z-index: 10;
      font-size: 1.2rem;
      padding: 0.5rem 1rem;
      background: white;
      border-radius: 0.5rem;
    }
  }
}
