@import 'src/common';

.Culture {
  width: 100%;
  margin: 0 auto;
}

.Culture_mobile {
  width: 100%;
  margin: 0 auto;
}
