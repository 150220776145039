@import 'src/common';

.Press {

  .item_wrap {
    @include flex-center-center;
    width: 1200px;
    margin: 50px auto;
    flex-wrap: wrap;
  }

  .article {
    width: 320px;
    border-radius: 10px;
    box-shadow: 3px 0 15px 0 rgba(49, 49, 49, 0.16);
    background-color: #fff;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border: 1px solid #dddddd;

    &:hover {
      transition: 0.5s 0s ease-in;
      transform: translateY(-10px);
      border: 1px solid #0008ff;
    }

    .pic {
      width: 100%;
      height: 220px;
      border-radius: 10px 10px 0 0;
      background-size: cover;
      background-position: center;
    }

    .main_top {
      width: 100%;
      display: flex;
      flex: 1 1 0%;
      flex-direction: column;
      padding: 2rem;

      .article_tit {
        width: 100%;
        height: 55px;
        font-size: 1.8rem;
        margin: 0 0 0.25rem;
        word-break: keep-all;
        line-height: 1.5;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .article_cont {
        @include flex-center-between;
        width: 100%;
        height: 36px;
        color: #999;
        font-size: 1.6rem;
        word-break: keep-all;
        line-height: 1.3;
        margin-top: 8px;

        .article_source {
          color: #005dac;
        }
      }
    }
  }
}

.Press_mobile {

  .item_wrap {
    @include flex-center-center;
    width: 100%;
    margin: 10px auto;
    flex-wrap: wrap;
  }

  .article {
    width: 320px;
    border-radius: 10px;
    border: 1px solid #dddddd;
    box-shadow: 3px 0 15px 0 rgba(49, 49, 49, 0.16);
    background-color: #fff;
    margin: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    .pic {
      width: 100%;
      height: 220px;
      border-radius: 10px 10px 0 0;
      background-size: cover;
      background-position: center;
    }

    .main_top {
      width: 100%;
      display: flex;
      flex: 1 1 0%;
      flex-direction: column;
      padding: 2rem;

      .article_tit {
        width: 100%;
        height: 40px;
        font-size: 1.4rem;
        margin: 0 0 0.25rem;
        line-height: 1.5;
        font-weight: bold;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .article_cont {
        @include flex-center-between;
        width: 100%;
        height: 20px;
        color: #999;
        font-size: 1.2rem;
        word-break: keep-all;
        line-height: 1.3;
        margin-top: 8px;

        .article_source {
          color: #005dac;
        }
      }
    }
  }
}
