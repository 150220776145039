@import 'src/common';

.page_title {
  @include flex-center-center;
  height: 80px;
  background-color: #005dac;
  position: relative;
  z-index: 2;
  letter-spacing: 5px;

  &.graduation {
    background-size: 100% 100%;

    img {
      width: 10px;
    }
  }

  span {
    color: white;
    font-size: 2.2rem;
    font-family: NanumSquareEB, serif;
    font-weight: bold;
    margin: 0 20px;
  }
}

.page_title_mobile {
  @include flex-center-center;
  height: 60px;
  background-color: #005dac;
  position: relative;
  z-index: 2;

  &.graduation {
    background-size: cover;
    background-repeat: no-repeat;

    img {
      width: 10px;
    }
  }

  span {
    color: white;
    font-size: 1.8rem;
    font-family: NanumSquareEB, serif;
    font-weight: bold;
    margin: 0 20px;
  }
}
