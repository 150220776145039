.history-nav-item {
  font-size: 1.5rem;
  &::before {
    content: "";
    width: 1px;
    left: 7px;
    position: absolute;
    height: 3.5rem;
    opacity: 0.5;
    background-color: white;
    transform: translateY(100%);
  }
  &:last-of-type::before {
    display: none;
  }
  @media (min-width: 1280px) {
    font-size: 2rem;
    &::before {
      left: 9px;
    }
  }
}
