.history-content {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  position: relative;
  padding-left: 25rem;
  @media (min-width: 1280px) {
    justify-content: flex-end;
  }
  .background-image {
    left: 0;
    top: 0;
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 0;
    transition: all 1s;
    opacity: 0;
    &.active {
      opacity: 0.5;
      filter: blur(16px);
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    position: relative;
    z-index: 10;
    background-color: #ffffff88;
    padding: 2rem;
    display: flex;
    width: max-content;
    justify-content: flex-start;
    align-items: stretch;
    gap: 2rem;
    .article:has(.main-img) {
      width: 600px;
    }
    .article {
      display: flex;
      flex-direction: column;
      width: 500px;
      height: 100%;
      gap: 2rem;
      .main-img {
        aspect-ratio: 16/9;
        height: 100%;
        position: relative;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: 8px solid #282828;
        }
      }
      .sub-img {
        width: 100%;
        aspect-ratio: 16/9;
        position: relative;
        @media (min-width: 1750px) {
          max-width: 600px;
        }
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: 8px solid #282828;
        }
      }
      p {
        font-size: 1.5rem;
        width: 100%;
        word-break: keep-all;
        white-space: pre-line;
        line-height: 1.25;
        color: #282828;
      }
      h3 {
        font-size: 2rem;
        color: #282828;
        font-weight: 700;
      }
    }
    .logo {
      position: absolute;
      width: 160px;
      right: 20px;
      bottom: -20px;
      transform: translateY(100%);
    }
  }
}
