@import 'src/common';

.MainSlide {
  width: 100%;
  height: 765px;
  margin-bottom: 125px;

  .visual_wrap {
    position: relative;
    height: 765px;

    .visu_slide {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;
      background-position: 0;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0;
      transition: 1s;

      &.On {
        opacity: 1;
      }

      &:nth-child(1) {
        background-color: rgba(241, 241, 241, 0.86);

        .visu_veil {
          background-color: rgba(241, 241, 241, 0.86);
        }
      }

      &:nth-child(2) {
        background-color: rgba(233, 237, 255, 0.84);

        .visu_veil {
          background-color: rgba(233, 237, 255, 0.84);
        }
      }

      &:nth-child(3) {
        background-color: rgba(241, 254, 255, 0.78);

        .visu_veil {
          background-color: rgba(241, 254, 255, 0.78);
        }
      }
    }

    .visu_veil {
      position: absolute;
      width: 0;
      top: 0;
      right: 0;
      height: 100%;
      opacity:0;
      transition: 2s;

      &.On {
        width: 40%;
        opacity: 1;
      }
    }

    .visu_txt_wrap {
      position: absolute;
      top: 0;
      right: 0;
      display: grid;
      width: 41%;
      height: 100%;
      opacity: 0;
      transition: 3s;
      text-align: right;

      &.On {
        opacity: 1;
      }
    }

    .visu_txt_cont {
      margin: auto;
      font-family: NanumSquareEB, serif;
      font-weight: bold;

      .visu_tit {
        font-size: 3.4rem;
        line-height: 1.16;
        margin-bottom: 15px;
        text-align: right;

        span {
          color: #0841a5;
        }
      }

      .visu_txt_sub01 {
        font-size: 2.2rem;
        line-height: 1.24;
        letter-spacing: 3px;
      }
    }
  }
}

.MainSlide.mobile {
  width: 100%;
  height: 443px;
  margin-bottom: 50px;

  .visual_wrap {
    position: relative;
    height: 100%;

    .visu_slide {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0;
      transition: 1s;

      &.On {
        opacity: 1;
      }

      &:nth-child(1) {
        background-color: rgba(241, 241, 241, 0.86);

        .visu_veil {
          background-color: rgba(241, 241, 241, 0.86);
        }
      }

      &:nth-child(2) {
        background-color: rgba(233, 237, 255, 0.84);

        .visu_veil {
          background-color: rgba(233, 237, 255, 0.84);
        }
      }

      &:nth-child(3) {
        background-color: rgba(241, 254, 255, 0.78);

        .visu_veil {
          background-color: rgba(241, 254, 255, 0.78);
        }
      }
    }

    .visu_veil {
      position: absolute;
      width: 0;
      top: unset;
      bottom: 0;
      right: 0;
      height: 121px;
      opacity:0;
      transition: 2s;

      &.On {
        //height: 121px;
        width: 100%;
        opacity: 1;
      }
    }

    .visu_txt_wrap {
      position: absolute;
      top: unset;
      bottom: 0;
      right: 27px;
      width: 100%;
      height: 121px;
      opacity: 0;
      transition: opacity 3s;

      &.On {
        opacity: 1;
      }
    }

    .visu_txt_cont {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      font-family: NanumSquareEB, serif;
      font-weight: bold;

      .visu_tit {
        font-size: 2rem;
        line-height: 1.16;
        margin-bottom: 0;
        text-align: right;

        span {
          color: #0841a5;
        }
      }

      .visu_txt_sub01 {
        font-size: 2.5rem;
        line-height: 1.24;
        letter-spacing: 3px;
      }
    }
  }
}
