.Main {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  p {
    font-family: KyoboHandwriting2020A, serif;
    font-size: 2.5rem;
    margin: 40px 0;
    text-align: center;
  }

  .LogoWrap {
    width: 100%;
    height: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .Logo {
      width: 70%;
      display: block;
    }
    
    @media screen and (max-width: 500px) {
      height: 25rem;
    }
  }

  IFrame {
    margin-bottom: 30px;
  }

  .bottom {
    position: relative;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    margin-top: -15%;
    z-index: -1;

    .back {
      width: 100%;
      height: 650px;
      background-repeat: round;
    }
  }

  .youtube_address {
    position: absolute;
    bottom: 150px;
    left: 80px;

    img {
      display: block;
      width: 40%;
      margin-bottom: 20px;
    }
    a {
      font-family: HaenamFont, serif;
      font-size: 2.5rem;
      font-weight: bold;
      text-decoration: none;
      color: #3b383a;
    }
  }
}

.Main_mobile {
  position: relative;
  width: 100%;
  margin: 0 auto;

  p {
    font-family: KyoboHandwriting2020A, serif;
    font-size: 2.5rem;
    margin: 40px 0;
    text-align: center;
  }

  .LogoWrap {
    width: 100%;
    height: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .Logo {
      width: 70%;
      display: block;
    }

    @media screen and (max-width: 500px) {
      height: 25rem;
    }
  }

  IFrame {
    margin-bottom: 30px;
  }

  .bottom {
    position: relative;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    margin-top: -15%;
    z-index: -1;

    .back {
      width: 100%;
      height: 650px;
      background-repeat: round;
    }
  }

  .youtube_address {
    position: absolute;
    bottom: 150px;
    left: 80px;

    img {
      display: block;
      width: 40%;
      margin-bottom: 20px;
    }
    a {
      font-family: HaenamFont, serif;
      font-size: 2.5rem;
      font-weight: bold;
      text-decoration: none;
      color: #3b383a;
    }
  }
}