.regions {
  width: 100%;
  margin: 0 auto;
  text-align: center;

  .pc_bg {
    width: 100%;
    min-width: 1400px;
    height: 985px;
    background-size: 100% 101%;
    margin: 0 auto;
    background-position: center center;
  }

  .wrap {
    width: 1400px;
    position: relative;
    margin: 0 auto;

    .top {
      text-align: left;
      position: absolute;
      top: -320px;
      left: 200px;

      img {
        width: 100%;
      }

      .main_txt {
        color: #010c54;
        font-family: GongGothicMedium, serif;
        font-size: 6.5rem;
        margin-bottom: 20px;
        line-height: 1.1;
        opacity: 0.6;
      }

      .eng {
        font-size: 2.0rem;
        color: #3ba5fe;
        font-weight: bold;
      }
      .desc {
        margin-top: 20px;
        font-size: 1.8rem;
        line-height: 1.5;
      }
    }

    .region_detail {
      margin-top: -280px;
      position: relative;
      z-index: 1;

      img {
        width: 70%;
        margin-top: 115px;
      }
    }

    .world {
      margin-top: 130px;
      position: relative;
      z-index: 1;

      img {
        width: 80%;
      }
    }
  }

  .bottom {

    .bg {
      width: 100%;
      min-width: 1400px;
      height: 745px;
      background-size: 100% 101%;
      margin: 0 auto;
      background-position: center center;
    }
  }
}

.regions_mobile {
  width: 100%;
  margin: -1px auto 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;

  .top {
    text-align: left;

    div {
      img {
        width: 100%;
      }
    }

    .main_txt {
      position: relative;
      top: -170px;
      left: 30px;

      color: #010c54;
      font-family: GongGothicMedium, serif;
      font-size: 35px;
      //font-weight: bold;
      line-height: 1.1;
      opacity: 0.6;
    }

    .eng {
      position: relative;
      top: -165px;
      left: 30px;

      font-size: 10px;
      color: #3ba5fe;
      font-weight: bold;
    }

    .desc {
      position: relative;
      top: -155px;
      left: 30px;

      font-size: 1.2rem;
      line-height: 1.5;
    }
  }

  .region_detail {
    position: relative;
    z-index: 1;
    margin-top: -130px;

    img {
      width: 85%;
    }
  }

  .world {
    position: relative;
    z-index: 1;
    margin-top: 40px;

    img {
      width: 90%;
    }
  }

  .bottom {
    position: relative;
    z-index: 0;
    margin-top: -35px;

    .bg {
      width: 100%;
      height: 230px;
      background-size: 100% 101%;
      margin: 0 auto;
      background-position: center center;
    }
  }
}