.neighbor {
  section {
    padding: 60px 0;
  }
  .container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }

  .top {
    text-align: center;
    .box {
      display: inline-grid;
      width: 420px;
      height: 420px;
      margin: 20px;
      position: relative;
    }
    .main_box {
      span {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 30px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      span:hover {
        cursor: pointer;
        box-shadow: 0 0 25px 0 black;
      }
      .title {
        position: absolute;
        bottom: 0;
        height: 55px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        p {
          color: white;
          font-size: 27px;
          font-weight: bold;
          letter-spacing: 1.3px;
          top: 12px;
          position: relative;
        }
      }
    }
  }

  .sv1 {
    background-color: #dff2fb;
  }
  .sv2 {
    h1 {
      color: #2a9fd6 !important;
    }
  }

  .title_box {
    text-align: left;
    h1 {
      color: #005dac;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 1.2px;
    }
    p {
      margin-top: 25px;
      font-size: 20px;
      line-height: 1.5;
    }
  }

  .img_box {
    margin-top: 30px;
    display: grid;
    span {
      width: 100%;
      height: 100%;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        padding: 10px;
        object-fit: cover;
      }
    }
  }
  .iv1 {
    grid-template-columns: 2fr 1fr 1fr;
    .big_img {
      grid-column: 1/ span 1;
    }
  }
  .iv2 {
    grid-template-columns: 1fr 2fr 1fr;
    .big_img {
      grid-column: 2/ span 1;
    }
  }
  .iv3 {
    grid-template-columns: 1fr 1fr 2fr;
    .big_img {
      grid-column: 3/ span 1;
    }
  }
  .big_img {
    grid-row: 1/3;
  }
}

.neighbor_mobile {
  .top {
    .container {
      margin: 20px auto;
      display: inline-grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .box {
      margin: 3px;
      position: relative;
    }
    .main_box {
      span {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
      span:after {
        content: "";
        display: block;
        padding-bottom: 30%;
      }
      .title {
        position: absolute;
        top: 35%;
        display: grid;
        margin: auto;
        width: 100%;
        height: 33%;
        background-color: rgba(0, 0, 0, 0.4);

        p {
          margin: auto;
          color: white;
          font-size: 1.2rem;
          width: 100px;
          text-align: center;
          word-break: keep-all;
          font-weight: bold;
        }
      }
    }
    .back {
      border-radius: 10px;
      background-color: #e7f8f8;
      height: 100%;
      display: grid;
      align-content: center;

      img {
        display: block;
        width: 60%;
        margin: auto;
      }
    }
  }

  .container {
    display: inline-block;
    margin: 30px 0;
    padding: 0 15px;
  }

  .sv1 {
    background-color: #dff2fb;
  }
  .sv2 {
    h1 {
      color: #2a9fd6 !important;
    }
  }

  .title_box {
    h1 {
      color: #005dac;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 1.2px;
    }
    p {
      margin-top: 10px;
      font-size: 12px;
      line-height: 1.4;
      padding: 0 3px;
    }
  }

  .img_box {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    span {
      width: 100%;
      height: 100%;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        padding: 3px;
        object-fit: cover;
      }
    }
  }
  .iv1 {
    grid-template-rows: 2fr 1fr 1fr;
  }
  .iv2 {
    grid-template-rows: 1fr 2fr 1fr;
  }
  .iv3 {
    grid-template-rows: 1fr 1fr 2fr;
  }
  .big_img {
    grid-column: 1/ span 2;
  }
}