@import "src/common";

.Direction {
  margin: 0 auto;

  .church_btn_wrap {
    @include flex-center-start;
    width: 1200px;
    margin: 50px auto 0;
    border-bottom: 2px solid #777777;

    .church_btn_box {
      &.active {
        border-bottom: 3px solid #005dac;

        .church_btn {
          color: #005dac;
          border: 1px solid #005dac;
          border-radius: 30px;
        }
      }
    }

    .church_btn {
      @include flex-center-center;
      width: 120px;
      height: 55px;
      font-size: 2rem;
      padding: 10px;
      margin: 10px;
      color: #777777;
      font-weight: bold;
    }
  }

  .map {
    @include flex-center-center;
    flex-direction: column;
    height: 692px;

    .welcome {
      width: 1200px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 60px;
      padding-left: 40px;
      background: #0067b2;
      font-size: 2rem;
      color: white;
      position: relative;
      bottom: 32px;

      img {
        height: 100%;
        position: absolute;
        right: 250px;
      }

      span {
        position: absolute;
        right: 80px;
        font-size: 2.0rem;
        font-weight: bold;
      }
    }
  }

  .desc_wrap {
    width: 1200px;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .desc_box {
      width: 600px;

      .desc_title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #0067b2;
        color: white;
        font-size: 2rem;
        font-weight: bold;
        width: 350px;
        height: 60px;
        padding-left: 40px;

        clip-path:
          polygon(
            0% 0px,                 /* top left */
            0px 0%,                 /* top left */
            calc(100% - 30px) 0%,   /* top right */
            100% 30px,              /* top right */
            100% calc(100% - 30px), /* bottom right */
            calc(100% - 30px) 100%, /* bottom right */
            0px 100%,               /* bottom left */
            0px 100%                /* bottom left */
          );
      }

      .desc_content {
        font-size: 1.8rem;
        font-weight: bold;
        min-height: 180px;
        padding-top: 40px;
        line-height: 1.2;

        li {
          font-size: 1.8rem;
          font-weight: bold;
          list-style-type: none;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          line-height: 1.5;

          .square {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: #0067b2;
            margin: 3px 10px 0 3px;
          }

          &.li_bus_wrap {
            align-items: flex-start;

            img {
              width: 20px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.Direction_mobile {
  margin: 0 auto;
  max-width: 400px;

  .church_btn_wrap {
    @include flex-center-between;
    width: 100%;
    margin: 4px auto 0;
    border-bottom: 2px solid #777777;

    .church_btn_box {
      &.active {
        border-bottom: 3px solid #005dac;

        .church_btn {
          color: #005dac;
          border: 1px solid #005dac;
          border-radius: 25px;
        }
      }
    }

    .church_btn {
      @include flex-center-center;
      height: 30px;
      font-size: 1.4rem;
      padding: 10px;
      margin: 10px;
      color: #777777;
      font-weight: bold;
    }
  }

  .map {
    @include flex-center-center;
    flex-direction: column;
    background: #f8f8f8;
    height: 350px;
  }

  .desc_wrap {
    width: 100%;
    margin: 50px auto;

    .desc_box {
      margin: 0 20px;
      width: 100%;

      .desc_title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #0067b2;
        color: white;
        font-size: 1.4rem;
        font-weight: bold;
        width: 50%;
        height: 30px;
        padding-left: 20px;

        clip-path:
                polygon(
                                0% 0px,                 /* top left */
                                0px 0%,                 /* top left */
                                calc(100% - 15px) 0%,   /* top right */
                                100% 15px,              /* top right */
                                100% calc(100% - 15px), /* bottom right */
                                calc(100% - 15px) 100%, /* bottom right */
                                0px 100%,               /* bottom left */
                                0px 100%                /* bottom left */
                );
      }

      .desc_content {
        font-size: 1.2rem;
        font-weight: bold;
        min-height: 60px;
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 1.2;

        li {
          font-size: 1.2rem;
          font-weight: bold;
          list-style-type: none;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          line-height: 1.5;

          .square {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #0067b2;
            margin: 3px 10px 0 3px;
          }

          &.li_bus_wrap {
            align-items: flex-start;

            img {
              width: 12px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
