.blue_sky_tv {
  margin: 0 auto;
  display: flow-root;
  background-size: cover;

  .top {
    width: 100%;
    height: 250px;
    margin-top: 161.4px;
    box-shadow: 7px 7px 10px 0 rgba(0, 66, 107, 0.2);
    background-color: rgba(256, 256, 256, 0.6);

    div {
      text-align: center;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      span {
        display: block;
        font-family: NanumSquareEB, serif;
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 1.57px;
        color: #004c9e;
      }
      img {
        width: 280px;
        height: 83px;
        margin: 12px 0 0;
        object-fit: contain;
      }
    }
  }

  .middle {
    margin-top: 110px;
    text-align: center;
    height: 220px;

    font-family: KoPubDotumMedium, serif;
    font-size: 2.2rem;
    line-height: 1.47;
    letter-spacing: -1.63px;
    color: #070304;

    .bold {
      font-size: 2.4rem;
      font-weight: bold;
      color: #004c9e;
    }
  }

  .body {
    margin: 0 auto;
    width: 1100px;

    div {
      float: left;
      margin-left: 20px;

      border-radius: 25px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-top: 30px;
    }

    .big_box {
      width: 700px;
      height: 275px;
    }
    .small_box {
      width: 340px;
      height: 275px;
    }

    .big_box:hover {
      box-shadow: 0 0 25px 0 black;
    }
    .small_box:hover {
      box-shadow: 0 0 25px 0 black;
    }
  }

  .bottom {
    display: inline-block;
    height: 360px;
    padding-top: 80px;
    text-align: center;
    width: 100%;

    span {
      width: 100%;
      font-size: 2.2rem;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.blue_sky_tv_mobile {
  margin: 0 auto;
  display: flow-root;
  background-size: cover;
  background-position: center;

  .top {
    height: 108px;
    margin-top: 40px;
    box-shadow: 2px 2px 5px 0 rgba(0, 66, 107, 0.2);
    background-color: rgba(256, 256, 256, 0.6);

    div {
      text-align: center;
      position: relative;
      top: 50%;
      transform: translateY(-53%);

      span {
        display: block;
        font-family: NanumSquareEB, serif;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 2;
        letter-spacing: 1.57px;
        color: #004c9e;
      }

      img {
        width: 35%;
        object-fit: contain;
      }
    }
  }

  .middle {
    text-align: center;
    word-break: keep-all;
    padding: 30px 20px;

    font-family: KoPubDotumMedium, serif;
    font-size: 1.3rem;
    line-height: 1.3;
    color: #070304;

    .bold {
      font-weight: bold;
      font-size: 1.3rem;
      color: #004c9e;
    }
  }

  .body {
    width: 100%;

    div {
      float: left;
      margin-left: 19px;
      border-radius: 15px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-top: 15px;
    }

    .big_box {
      width: 90%;
      height: 150px;
    }

    .small_box {
      width: 43%;
      height: 125px;
    }
  }

  .bottom {
    width: 100%;
    height: 260px;
    display: inline-block;
    text-align: center;
    margin-bottom: 30px;
    padding-top: 40px;

    span {
      text-decoration: underline;
      font-size: 1.2rem;
      font-family: KoPubDotumMedium, serif;
    }
  }
}