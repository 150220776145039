.SpotlightItem {
  width: 42rem;
  border-radius: 10px;
  box-shadow: 3px 0 15px 0 rgba(49, 49, 49, 0.16);
  background-color: #fff;
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border: 1px solid #dddddd;

  &:hover {
    transition: 0.5s 0s ease-in;
    transform: translateY(-10px);
    border: 1px solid #0008ff;
  }

  .pic {
    width: 100%;
    height: 38rem;
    border-radius: 10px 10px 0 0;
    background-size: cover;
    background-position: center;
  }

  .main_top {
    width: 100%;
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    padding: 2rem;

    .spot_tit {
      width: fit-content;
      height: 45px;
      font-size: 2.4rem;
      margin: 0 0 0.25rem;
      line-height: 1.5;
      word-break: break-word;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: bold;
      border-bottom: 3px solid #005dac;

      .tag {
        font-weight: 600;
        font-size: 2.4rem;
        margin-right: 5px;
      }
    }

    .spot_cont {
      width: 100%;
      height: 100px;
      color: #4e4e4e;
      font-size: 2.0rem;
      word-break: keep-all;
      line-height: 1.3;
      margin-top: 8px;
    }
  }
}

.SpotlightItem_mobile {
  width: 100%;
  height: 163px;
  border-radius: 10px;
  box-shadow: 3px 0 15px 0 rgba(49, 49, 49, 0.16);
  background-color: #fff;
  margin: 0 10% 25px 10%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border: 1px solid #dddddd;

  &:hover {
    transition: 0.5s 0s ease-in;
    transform: translateY(-10px);
    border: 1px solid #0008ff;
  }

  .pic {
    width: calc(100% - 136px);
    height: 163px;
    border-radius: 10px 0 0 10px;
    background-size: cover;
    background-position: center;
  }

  .main_top {
    width: 136px;
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    padding: 1.8rem;

    .spot_tit {
      width: fit-content;
      height: auto;
      font-size: 1.2rem;
      margin: 0 0 0.25rem;
      padding-bottom: 8px;
      line-height: 1.5;
      word-break: break-word;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: bold;
      border-bottom: 1px solid #005dac;

      .tag {
        font-weight: 600;
        font-size: 2.4rem;
        margin-right: 5px;
      }
    }

    .spot_cont {
      width: 100%;
      height: auto;
      color: #4e4e4e;
      font-size: 1rem;
      word-break: keep-all;
      line-height: 1.3;
      margin-top: 8px;
    }
  }
}