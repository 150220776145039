.history-nav {
  z-index: 20;
  position: fixed;
  left: 6rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
