.church {
  width: 1200px;
  margin: 0 auto;

  .top {
    text-align: center;

    .church_icon {
      margin-top: 85px;
      width: 45px;
      height: 45px;
    }

    h1 {
      margin-top: 50px;
      color: #1c5e97;
      font-size: 2.5rem;
      letter-spacing: 6px;
      font-family: GongGothicMedium, serif;
    }
  }

  .middle {
    margin-top: 90px;
    display: inline-flex;

    .desc {
      width: 58%;
      padding-left: 60px;
      font-size: 1.6rem;
      line-height: 1.5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .chief {
      display: inline-block;
      width: 50%;

      span {
        width: 100%;
        padding-left: 20px;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
      }

      img {
        width: 100%;
        border-radius: 20px;
      }
    }
  }

  .church_imgs {
    position: relative;
    z-index: 1;
    margin-top: 150px;
    display: inline-flex;
    height: 289px;
    width: 100%;

    span {
      width: 31%;
      height: 100%;
      margin: auto;
      transition: transform .2s;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      box-shadow: 3px 0 15px 0 rgba(49, 49, 49, 0.16);
      object-fit: cover;
    }
  }

}

.church_bottom {
  position: relative;
  z-index: 0;
  margin-top: -300px;
  margin-bottom: -1px;

  img {
    width: 100%;
  }
}

.church_mobile {
  margin: 0 auto;

  .top {
    text-align: center;

    .church_icon {
      margin-top: 30px;
      width: 25px;
      height: 25px;
    }
    h1 {
      margin-top: 10px;
      color: #1c5e97;
      font-size: 25px;
      letter-spacing: 4px;
      font-family: GongGothicMedium, serif;
    }
  }

  .middle {
    margin-top: 35px;

    .desc {
      font-size: 1.2rem;
      line-height: 1.5;
      padding: 0 35px;
      margin-top: 30px;
      word-break: keep-all;
    }
    .chief {
      padding: 0 25px;
      margin-top: 15px;
      height: 250px;

      span {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }

  .church_imgs {
    position: relative;
    z-index: 1;
    margin-top: 90px;

    span {
      width: 100%;
      padding: 0 60px;
      margin-bottom: 20px;
    }
    img {
      width: 100%;
      height: 100%;
      box-shadow: 3px 0 15px 0 rgba(49, 49, 49, 0.16);
      object-fit: cover;
      border-radius: 10px;
    }
  }

}

.church_bottom_mobile {
  position: relative;
  z-index: 0;
  margin-bottom: -1px;

  img {
    width: 100%;
  }
}