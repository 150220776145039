.reservation {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: auto;
  .errorMessage {
    color: #4495ff;
    font-size: 14px;
  }
  .background-image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    filter: blur(5px);
  }
  .form-container {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 40px;
    width: 100%;
    max-width: 480px;
    position: relative;
    z-index: 10;
    background-color: #00000088;
    border-radius: 16px;
    font-size: 16px;
    > .title {
      font-size: 18px;
    }
    select {
      background-color: white;
      color: black;
      border-radius: 5px;
      padding: 5px 10px;
    }
    input {
      background-color: white;
      color: black;
      border-radius: 5px;
      padding: 5px 10px;
    }
    textarea {
      background-color: white;
      color: black;
      border-radius: 5px;
      padding: 5px 10px;
    }
    p {
      padding-top: 5px;
    }
    .form-inputs-container {
      display: grid;
      @media (min-width: 500px) {
        grid-template-columns: 60px 1fr;
      }
      gap: 16px;
      text-align: left;
      width: 100%;
      > textarea {
        resize: none;
        height: auto;
      }
      .calendar {
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: #ffffff;
        border-radius: 5px;
        padding: 10px;
        .months {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > button {
            color: #3f4553;
            outline: 1px solid #3f4553;
            padding: 5px 10px;
            border-radius: 5px;
            &.disable {
              color: #3f4553;
              background-color: #3f455355;
              outline: none;
            }
          }
          > .title {
            color: #3f4553;
            font-weight: bold;
          }
        }
        .dates {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          justify-items: center;
          row-gap: 5px;
          column-gap: 5px;
          .day {
            color: #3f4553;
          }
          .date {
            color: #969cab;
            padding: 5px;
            @media (min-width: 500px) {
              padding: 5px 10px;
            }
            border-radius: 5px;
            background-color: white;
            text-align: center;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            outline: 1px solid #3f455344;
            transition: all 0.3s;
            &:hover {
              outline: 1px solid #3f4553;
              color: #3f4553;
            }
            &.disable {
              color: #3f4553;
              background-color: #3f455355;
              pointer-events: none;
            }
            &.thisMonth {
              color: #3f4553;
            }
            &.select {
              color: white;
              background-color: #2d4888;
            }
          }
        }
        .times {
          display: grid;
          justify-content: space-between;
          grid-template-columns: repeat(auto-fill, 56px);
          gap: 5px;
          .time {
            display: block;
            background-color: white;
            color: #3f4553;
            outline: 1px solid #3f455344;
            text-align: center;
            padding: 5px 0;
            border-radius: 5px;
            transition: all 0.3s;
            &:hover {
              outline: 1px solid #3f4553;
              color: #3f4553;
            }
            &.select {
              background-color: #2d4888;
              color: white;
              outline: 1px solid #2d4888;
            }
            &.disable {
              color: gray;
            }
          }
        }
      }
    }
    .form-check-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: end;
      .check-label {
        display: flex;
        align-items: center;
        gap: 5px;
        > p {
          padding-top: 0;
        }
      }
      .submit-button {
        background-color: white;
        text-align: center;
        padding: 5px;
        width: 100%;
        border-radius: 5px;
        color: black;
      }
    }
  }
}
