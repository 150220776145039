.image-rotator {
  position: relative;
  .rotate-img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
}
