.blue-image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  z-index: -10;
  opacity: 0;
  transition: all 1s;
  mix-blend-mode: multiply;
  left: 10px;
}
