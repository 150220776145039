@import "src/common";

.Greetings {
  width: 100%;

  .section1, .section2 {
    width: 100%;
    height: 842px;
  }

  .section1 {
    background-color: #fafafa;
  }

  .section2 {
    background-color: #e6f4fc;
  }

  .wrapper {
    width: 100%;
    max-width: 1400px;
    height: 100%;
    margin: 0 auto;
    @include flex-center-center;

    &.branch_chief {
      .left_side img {
        top: 20%;
      }
    }

    .left_side {
      width: 50%;
      height: 100%;
      position: relative;
      @include flex-center-end;

      img {
        width: 430px;
        position: absolute;
        top: 20%;
        right: 30px;
      }

      .box1, .box2 {
        width: 430px;
        height: 440px;
        background-color: #f8deb3;
        margin-bottom: 130px;

        &:before {
          width: 430px;
          height: 440px;
          content: "";
          display: block;
          border: 6px solid #deb887;
          margin: 53px 0 0 -60px;
        }
      }

      .box2 {
        background-color: #ffffff;
        margin-bottom: 16px;

        &:before {
          border: 6px solid #ffffff;
          margin: 40px 0 0 -62px;
        }
      }
    }

    .right_side {
      width: 50%;
      height: 100%;
      padding-left: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .right_title {
        font-weight: bold;
        font-size: 2.0rem;
        line-height: 1.42;
        letter-spacing: -0.65px;
        color: #0841a5;
      }

      .right_content {
        font-size: 1.6rem;
        color: #4e4e4e;
        line-height: 1.3;
      }

      .right_name {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.5;
        color: #4e4e4e;
        text-align: right;
        margin: 27px 170px 0 0;
      }
    }
  }
}

.Greetings_mobile {
  width: 100%;

  .section1, .section2 {
    width: 100%;
  }

  .section1 {
    background-color: #fafafa;
  }

  .section2 {
    background-color: #e6f4fc;
  }

  .wrapper {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    @include flex-center-center;
    flex-direction: column;
    position: relative;

    &.branch_chief {
      .left_side {
        margin-bottom: 10px;

        img {
          top: 10%;
        }
      }
    }

    .left_side {
      width: 100%;
      height: 328px;
      margin-bottom: 38px;
      @include flex-center-center;

      img {
        width: 200px;
        position: absolute;
        top: 9%;
      }

      .box1, .box2 {
        width: 200px;
        height: 220px;
        background-color: #f8deb3;
        margin: 12px 0 0 30px;

        &:before {
          width: 200px;
          height: 220px;
          content: "";
          display: block;
          border: 6px solid #deb887;
          margin: 30px 0 0 -35px;
        }
      }

      .box2 {
        background-color: #ffffff;
        margin-bottom: -12px;

        &:before {
          border: 6px solid #ffffff;
          margin: 24px 0 0 -41px;
        }
      }
    }

    .right_side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 38px;
      margin-bottom: 70px;
      word-break: keep-all;

      .right_title {
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.42;
        letter-spacing: -0.3px;
        color: #0841a5;
      }

      .right_content {
        font-size: 1.2rem;
        color: #4e4e4e;
        line-height: 1.5;
      }

      .right_name {
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.5;
        color: #4e4e4e;
        text-align: right;
        margin: 0;
      }
    }
  }
}
