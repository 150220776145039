.corona {
  section {
    padding: 60px 0;
  }
  .container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }

  .top {
    text-align: center;
    .box {
      display: inline-grid;
      width: 420px;
      height: 420px;
      margin: 20px;
      position: relative;
    }
    .main_box {
      span {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 30px;
        }
      }
      img:hover {
        cursor: pointer;
        box-shadow: 0 0 12px 5px rgba(49, 49, 49, 0.16);
      }
      .title {
        position: absolute;
        bottom: 0;
        height: 55px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        p {
          color: white;
          font-size: 27px;
          font-weight: bold;
          letter-spacing: 1.3px;
          top: 12px;
          position: relative;
        }
      }
    }
  }

  .sv1 {
    background-color: #dff2fb;
  }
  .sv2 {
    h1 {
      color: #2a9fd6 !important;
    }
  }

  .title_box {
    text-align: left;
    h1 {
      color: #005dac;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 1.2px;
    }
    p {
      margin-top: 25px;
      font-size: 20px;
      line-height: 1.5;
    }
  }

  .img_box {
    margin-top: 30px;
    display: grid;
    span {
      width: 100%;
      height: 100%;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        padding: 10px;
        object-fit: cover;
      }
    }
  }
  .iv1 {
    grid-template-columns: 1fr 2fr 1fr;
    .big_img {
      grid-row: 1/3;
      grid-column: 2/ span 1;
    }
  }
  .iv2 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 355px 355px;
    .big_img1 {
      grid-column: 2/ span 2;
    }
    .big_img2 {
      grid-column: 1/ span 2;
    }
  }
}

.corona_mobile {

  .container {
    display: inline-block;
    margin: 30px 0;
    padding: 0 15px;
  }

  .sv1 {
    background-color: #dff2fb;
  }
  .sv2 {
    h1 {
      color: #2a9fd6 !important;
    }
  }

  .title_box {
    h1 {
      color: #005dac;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 1.2px;
    }
    p {
      margin-top: 10px;
      font-size: 12px;
      line-height: 1.4;
      padding: 0 3px;
    }
  }

  .img_box {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    span {
      width: 100%;
      height: 100%;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        padding: 3px;
        object-fit: cover;
      }
    }
  }
  .iv1 {
    grid-template-rows: 1fr 2fr 1fr;
    .big_img {
      grid-column: 1/ span 2;
    }
  }
  .iv2 {
    grid-template-rows: 1fr 1fr;
    .big_img2 {
      grid-column: 1/ span 2;
    }
    .big_img3 {
      grid-column: 1/ span 2;
    }
  }
}