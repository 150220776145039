.Footer {
  height: 237px;
  width: 100%;
  background-color: #808080;
  color: #ffffff;
  position: relative;
  z-index: 2;

  .content {
    width: 95%;
    max-width: 1400px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 270px;
    }

    .contact {
      font-size: 1.6rem;
      line-height: 1.29;
      letter-spacing: 1.2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 600px;
    }
  }
}

.Footer.mobile {
  height: 143px;
  width: 100%;
  background-color: #808080;
  color: #ffffff;

  .content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
    text-align: center;
    padding: 28px 0;

    img {
      width: 120px;
      margin-bottom: 15px;
    }

    .contact {
      font-size: 1.2rem;
      font-weight: 300;
      line-height: 1.5;
      letter-spacing: 0.45px;
      display: block;
      text-align: center;
      width: 100%;
    }
  }
}