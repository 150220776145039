@import 'src/common';

.culture_btn_wrap {
  @include flex-center-center;
  width: 1200px;
  margin: 50px auto 0;

  .culture_btn_box {
    &.active {
      .culture_btn {
        color: #005dac;
        border: 1px solid #005dac;
        border-radius: 30px;
      }
    }
  }

  .culture_btn {
    @include flex-center-center;
    width: 200px;
    height: 55px;
    font-size: 2rem;
    padding: 10px;
    margin: 10px;
    color: #777777;
    font-weight: bold;
  }
}

.culture_btn_wrap_mobile {
  @include flex-center-center;
  width: 100%;
  margin: 5px auto 0;
  border-bottom: 1px solid #dddddd;

  .culture_btn_box {
    width: 50%;

    &.active {
      border-bottom: 3px solid #005dac;

      .culture_btn {
        color: #005dac;
        border: 1px solid #005dac;
        border-radius: 25px;
      }
    }
  }

  .culture_btn {
    @include flex-center-center;
    height: 30px;
    font-size: 1.4rem;
    padding: 10px;
    margin: 10px auto;
    color: #a4a4a4;
    font-weight: bold;
  }
}
