@import "src/common";

.Header {
  width: 100%;
  max-width: 1920px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  position: relative;

  .logo_blue {
    width: 185px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  .nav {
    width: 800px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: NanumSquareEB, serif;

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.8rem;
      font-weight: bold;
      height: 100%;

      &:hover, &.active {
        color: #005dac;
        font-weight: bold;
      }
    }

    .sub_menu {
      display: none;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      position: absolute;
      top: 85px;
      background: white;
      z-index: 100;
      min-width: 150px;
      border-radius: 6px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
      padding: 13px 0;
      color: #1b1b1b;

      &.active {
        display: flex;
      }

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 47px;
        padding: 10px 20px;
        font-size: 1.6rem;
        font-weight: normal;

        &:hover {
          color: #005dac;
          background: #f6f6f6;
          font-weight: bold;
        }
      }
    }

    .family_site_btn {
      background: none;
      border: 1px solid #005dac;
      padding: 10px 14px;
      font-size: 1.6rem;
      font-weight: bold;
      border-radius: 10px;

      .sub_menu {
        display: none;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        position: absolute;
        top: 85px;
        right: 0;
        background: white;
        z-index: 100;
        min-width: 150px;
        border-radius: 6px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
        padding: 13px 0;
        color: #1b1b1b;

        &.active {
          display: flex;
        }

        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          height: 47px;
          padding: 10px 20px;
          font-size: 1.6rem;
          font-weight: normal;

          &:hover {
            color: #005dac;
            background: #f6f6f6;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.Header_mobile {
  width: 100%;
  height: 60px;
  margin: 0 auto;
  display: block;
  text-align: center;
  padding: 14px 0 7px;
  position: relative;

  .logo_blue {
    width: 112px;
    margin: auto;
    img {
      width: 100%;
    }
  }

  .logo_white {
    width: 155px;
    height: auto !important;
    border-bottom: none !important;
    margin-bottom: 30px;
    img {
      width: 100%;
    }
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 30px;
    height: 20px;
    left: 30px;
    top: 22px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #999999;
    height: 10% !important;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    top: 35px !important;
    right: 20px !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #9dd2ff;
    height: 24px !important;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #004e90;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    //padding: 30px 20px 20px;
  }

  /* Individual item */
  .bm-item {
    color: white;
    font-size: 1.8rem;
    font-weight: bold;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    height: 58px;
    border-bottom: 1px solid #1c5e97;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
  }

  .sub-menu-wrap {
    flex-direction: column;
    align-items: flex-start;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
    background: #1c5e97;

    &.open {
      visibility: visible;
      opacity: 1;

      &.five {
        height: 200px;
      }

      &.four {
        height: 164px;
      }

      &.three {
        height: 170px;
      }
    }
  }

  .menu-item-sub {
    color: white;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 2.5;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 36px;
    padding: 0 18px;

    &:first-child {
      margin-top: 12px;
    }

    &:last-child {
      margin-bottom: 12px;
    }

    &.active {
      color: #004e90;
      font-weight: bold;
      background-color: white;
    }
  }

  .site_wrap {
    width: 100%;
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
    flex-wrap: wrap;
    border: 0;
    margin-top: 10px;

    .site_icon {
      width: 70px;
      height: 55px;
      margin: 8px;
      padding: 2px;
      opacity: 0.9;
    }
  }
}
