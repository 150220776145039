@import "src/common";

.Graduation {
  width: 100%;
  margin: 0 auto;

  .Main_banner {
    width: 100%;
    height: 500px;
    overflow: hidden;
    position: relative;

    .banner_txt {
      width: 100%;
      color: white;
      font-size: 4.0rem;
      text-align: center;
      line-height: 1.4;
      position: absolute;
      top: 205px;
      left: 0;
      text-shadow: 5px 5px 3px rgba(0, 0, 0, 0.7);
      z-index: 10;
    }

    img {
      width: 100%;
    }
  }

  .Logo {
    width: 100%;
    height: 353px;
    padding: 280px 0;
    @include flex-center-center;

    img {
      width: 600px;
    }
  }

  .intro_txt {
    text-align: center;
    font-size: 2.0rem;
    font-weight: 500;
    line-height: 1.3;
    width: 100%;
    height: 400px;
    padding-top: 72px;
    margin: 120px 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 715px 90%;

    span {
      font-weight: bold;
      font-size: 2.0rem;
    }
  }

  ul {
    margin: 275px 0 47px;

    li {
      @include flex-center-end;
      width: 1200px;
      margin: 0 auto 24px;

      .with_round {
        width: 220px;
        height: 220px;
        border-radius: 50%;
        background-color: #005da8;
        color: #ffffff;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
        @include flex-center-center;
      }

      .line {
        width: 462px;
        height: 2px;
        background-color: #002b80;
      }

      .mid_txt {
        width: 460px;
        text-align: center;
        display: flex;
        flex-direction: column;

        p, span {
          font-size: 2.2rem;
          line-height: 1.5;
          padding: 15px;
        }

        p {
          font-weight: 500;
        }
        span {
          font-weight: bold;
        }

        .bar {
          width: 462px;
          height: 2px;
          background-color: #002b80;
          @include flex-center-between;

          &:before {
            width: 20px;
            height: 20px;
            background-color: #005da8;
            border-radius: 50%;
            display: block;
            content: "";
          }
        }
      }

      .pic_box {
        width: 460px;

        img {
          width: 100%;
        }
      }
    }
  }

  .bg_box {
    width: 100%;
    overflow: hidden;
    margin-top: -200px;
    position: relative;
    z-index: -1;

    img {
      width: 101%;
      height: 600px;
    }
  }
}

.Graduation_mobile {
  width: 100%;
  margin: 0 auto;

  .Main_banner {
    width: 100%;
    overflow: hidden;
    position: relative;

    .banner_txt {
      @include flex-center-center;
      width: 100%;
      height: calc(100vw * 0.65);
      color: white;
      font-size: 2.4rem;
      text-align: center;
      line-height: 1.4;
      position: absolute;
      text-shadow: 5px 5px 3px rgba(0, 0, 0, 0.7);
      z-index: 10;
    }

    img {
      width: 100%;
    }
  }

  .Logo {
    width: 100%;
    height: 100px;
    padding: 140px 0;
    @include flex-center-center;

    img {
      width: 305px;
    }
  }

  .intro_txt {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.3;
    width: 100%;
    height: 270px;
    padding-top: 65px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 355px 75%;

    span {
      font-weight: bold;
      font-size: 1.3rem;
    }
  }

  ul {
    margin: 90px 0 0;
    height: 650px;
    @include flex-center-between;
    align-items: flex-end;
    flex-direction: column;

    li {
      @include flex-center-end;
      margin: 0 24px;

      .with_round {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #005da8;
        color: #ffffff;
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
        @include flex-center-center;
      }

      .line {
        width: 116px;
        height: 2px;
        background-color: #002b80;
      }

      .mid_txt {
        width: 155px;
        text-align: center;
        display: flex;
        flex-direction: column;

        p, span {
          font-size: 1.2rem;
          line-height: 1.5;
          padding: 5px;
        }

        p {
          font-weight: 500;
          word-break: keep-all;
        }

        span {
          font-weight: bold;
        }

        .bar {
          width: 155px;
          height: 2px;
          background-color: #002b80;
          @include flex-center-between;

          &:before {
            width: 6.5px;
            height: 6.5px;
            background-color: #005da8;
            border-radius: 50%;
            display: block;
            content: "";
          }
        }
      }

      &:last-child {
        .mid_txt {
          width: 110px;
        }
      }

      .pic_box {
        width: 130px;

        img {
          width: 100%;
        }
      }
    }
  }

  .bg_box {
    width: 100%;
    overflow: hidden;

    img {
      width: 101%; // 킹받게 옥의 티가 있음.ㅎ.
    }
  }
}
