.relation-center-main {
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: #444444;
  .image-rotator {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0.1;
    filter: grayscale(0);
  }
  .container {
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    .background-figure {
      width: 160px;
      position: fixed;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100%;
      }
    }
    .overlay-text {
      position: fixed;
      font-family: "Noto Serif KR";
      left: 50%;
      top: 30%;
      transform: translate(-50%, -50%);
      font-size: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      width: 100%;
      .header1 {
        color: white;
        font-size: 24px;
        font-weight: 500;
      }
      .paragraph {
        color: white;
        font-size: 16px;
      }
    }
    .link-container {
      color: white;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid;
      border-color: #575768;
      &:first-of-type {
        border-left: 1px solid;
        border-color: #575768;
      }
      gap: 20px;
      overflow: hidden;
      position: relative;
      .text {
        font-size: 16px;
      }
      .icon {
        font-size: 24px
      }
      &:hover .blue-image-hover {
        opacity: 0.6;
        left: 0;
      }
    }
  }
}

@media (min-width: 720px) {
  .relation-center-main {
    .container {
      .link-container {
        width: 25%;
      }
    }
  }
}

@media (min-width: 1280px) {
  .relation-center-main {
    .container {
      .link-container {
        width: 20%;
      }
    }
  }
}
